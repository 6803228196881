<template>
  <base-dialog
    :visible.sync="currentVisible"
    :showFooter="false"
    title="详情"
    class="pad20"
  >
    <div>
      <formInfo
        :count="count"
        :componentList="formInfoconfig"
        :model="detailInfo"
      ></formInfo>
    </div>
    <template slot="footer">
      <base-button
        label="关 闭"
        type="default"
        @click="
          () => {
            this.currentVisible = false;
          }
        "
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import FormInfo from '@/components/packages/form-info/form-info.vue'
export default {
  components: { baseDialog, BaseButton, FormInfo },
  name: 'detailDialog',
  props: {
    formInfoconfig: Array,
    detailInfo: Object,
    visibleDetail: Boolean
  },
  data () {
    return {
      count: 0
    }
  },
  computed: {
    currentVisible: {
      get () {
        return this.visibleDetail
      },
      set (val) {
        this.$emit('update:visibleDetail', val)
      }
    }
  },
  methods: {
    // 修改值改变弹窗内容
    setCount () {
      this.count++
    }
  }
}
</script>
